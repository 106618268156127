import { TablePaginationConfig } from 'antd';
import { isMissing, isPresent } from '../utils/ts_helpers';
import STIClient from "./STIClient";
import { Bankverbindung, Kontakt, Kunde, KundenAdresse, KundenView, LeascomSyncState, PageType, Sorter, Vermittler } from './api-types';

const KUNDEN_REST_BASE_URL = '/kunden';
const BANKVERBINDUNG_REST_BASE_URL = '/bankverbindung';

class KundenService {

    async getBankByBlz(blz: string, signal: AbortSignal):Promise<Kunde[]> {
        const res = await STIClient.get(KUNDEN_REST_BASE_URL + '/blz?blz=' + blz, {
            signal: signal
        })
        return res.data;
    }


    async getAllKunden(): Promise<Kunde[]> {
        const res = await STIClient.get(KUNDEN_REST_BASE_URL, {
            method: 'GET',
        });
        return res.data;
    }

    /**
     * 
     * @param key key word to search for
     * @param signal signal to cancel ajax request
     * @param rechtsform rechtsform to search for
     * @returns all customers fullfilled searching requirements
     */

    async getAllKundenByKeyAndRechtsform(key: string, signal: AbortSignal, rechtsform: number[]): Promise<Kunde[]> {
        const res = await STIClient.get(KUNDEN_REST_BASE_URL + '/findAll', {
            params: { key, rechtsform },
            paramsSerializer: params => {
                return Object.keys(params)
                    .map(key =>
                        key === 'rechtsform' ?
                            params[key]?.map((p: number) => `${key}=${p}`).join('&')
                            : `${key}=${encodeURIComponent(params[key])}`).join('&');
            },
            signal: signal
        });
        return res.data;
    }

    /**
     * 
     * @param name key word to search for
     * @param signal signal to cancel ajax request
     * @returns all customers fullfilled searching requirements
     */

    async getAllKundenByName(name: string, signal: AbortSignal,): Promise<Kunde[]> {
        const res = await STIClient.get(KUNDEN_REST_BASE_URL + `/findByName?name=${name}`, {
            signal: signal
        });
        return res.data;
    }

    /**
     * 
     * @param key key word to search for
     * @param signal signal to cancel ajax request
     * @returns all customers fullfilled searching requirements
     */

async getAllVersorger(versorger: string, signal: AbortSignal): Promise<Kunde[]> {
        const res = await STIClient.get(KUNDEN_REST_BASE_URL + '/versorger', {
            params: { versorger },
            paramsSerializer: params => {
                return Object.keys(params)
                    .map(key => `${key}=${encodeURIComponent(params[key])}`).join('&');
            },
            signal: signal
        });
        return res.data;
    }

    async createNewKunde(kunde: Kunde): Promise<Kunde> {
        const res = await STIClient.post(KUNDEN_REST_BASE_URL + '/new', kunde);
        return res.data;
    }

    async getKundeById(id: number): Promise<Kunde> {
        const res = await STIClient.get(KUNDEN_REST_BASE_URL + '/' + id);
        return res.data;
    }

    async updateKunde(kunde: Kunde, id: number): Promise<Kunde> {
        const res = await STIClient.put(KUNDEN_REST_BASE_URL + '/' + id, kunde);
        return res.data;
    }

    /**
     * 
     * @param id customer id
     * @returns void
     */

    async deleteKunde(id: number) {
        return;
        // temporary deactivation
        // return await STIClient.delete(KUNDEN_REST_BASE_URL + '?id=' + id);
    }

    /**
     * 
     * @param ids customer id array
     * @returns all customers with id from customer id array
     */

    async findAllById(ids: number[]): Promise<Kunde[]> {
        const res = await STIClient.get(KUNDEN_REST_BASE_URL + '?ids=' + ids.join(','));
        return res.data;
    }

    /**
     * 
     * @param config customer table configuration to search for
     * @param signal signal to cancel ajax request
     * @returns all customers fullfilled searching requirements
     */

    async globalSearch(params: { [key: string]: string | string[] }, columns: Array<string>, pagination: TablePaginationConfig, sorters: Sorter[], signal: AbortSignal): Promise<PageType<KundenView>> {
        const res = await STIClient.get(KUNDEN_REST_BASE_URL + '/globalSearch', {
            params: { ...params, columns: columns.filter(s => isPresent(s) && s !== 'Matching Score').map(s => s.split(' ').map((s,i) => 
            i === 0 ? s.toLowerCase(): s.toLowerCase().charAt(0).toUpperCase() + s.toLowerCase().slice(1)).join('')).join(','), currentPage: pagination.current ?? 0, pageSize: pagination.pageSize, sorters: sorters },
            paramsSerializer: params => {
                return Object.keys(params)
                    .map(key => ['sorters'].includes(key) ?
                        `${key}=${encodeURIComponent(JSON.stringify(params[key].filter((s: Sorter) => s.column !== 'Matching Score')))}` :
                        Array.isArray(params[key]) && params[key].length > 0 ?
                            params[key]?.map((p: number) => `${key}=${encodeURIComponent(p)}`).join('&') : `${key}=${encodeURIComponent(params[key])}`).join('&');
            },
            signal: signal,
        });

        return res.data;
    }

    async merge({
        source1,
        source2,
        kunde,
        addresses,
        bankaccounts,
        contacts,
        vermittler,
    }: MergeRequest) {
        const res = await STIClient.post(
            KUNDEN_REST_BASE_URL + '/merge', 
            { source1, source2, customer: kunde, addresses, bankaccounts: [], contacts, vermittler }
        );
        const savedKunde: Kunde = res.data;
        // quick and dirty solution, save bankverbindungen separately, TODO: make the data model clean
        await Promise.all(bankaccounts.map(b => STIClient.post(BANKVERBINDUNG_REST_BASE_URL + '/new', {...b, kunde: savedKunde.id })));
        const customerRes = await STIClient.get(KUNDEN_REST_BASE_URL + '/' + savedKunde.id); 
        return customerRes.data;
    }

    async getSyncState(kundeId?: number): Promise<LeascomSyncState | null> {
        if (isMissing(kundeId)) {
            return null;
        }
        const res = await STIClient.get(KUNDEN_REST_BASE_URL + '/' + kundeId + '/syncState');

        if (isPresent(res.data.timestamp)) {
            res.data['timestamp'] = new Date(res.data.timestamp);
        }

        return res.data;
    }
}

export type { Kunde };

export interface MergeRequest {
    source1: number
    source2: number
    blz?: string
    bicswift?: string
    kunde: Kunde
    addresses: Array<KundenAdresse>
    bankaccounts: Array<Bankverbindung>
    contacts: Array<Kontakt>
    vermittler: Array<Vermittler>
}

const kundenService = new KundenService();
export default kundenService;
